export default {
  ru: {
    shared: {
      integration_title: 'Отключить интеграцию',
      yes_confirm: 'Да, отключить',
      copy: 'скопировано',
      integration_description: 'Вы действительно хотите отключить интеграцию \n' +
          'с TravelLine?',
      anavailable: 'Недоступен',
      downloadLetter: 'Скачать ГП',
      free: 'Бесплатно',
      unset: 'Не задана',
      unactive: 'Неактивно',
      clipboard_copy: 'Копировать в буфер обмена',
      menu_header: 'ЭКСТРАНЕТ',
      login_title: 'Управление санаторием',
      email: 'Электронная почта',
      email_placeholder: 'Введите email',
      password: 'Пароль',
      password_placeholder: 'Введите пароль',
      login: 'Войти',
      logout: 'Выйти',
      cancel: 'Отмена',
      cancel_do: 'Отменить',
      switch_off: 'Отключить',
      switch: 'Подключить',
      close: 'Закрыть',
      confirm: 'Сохранить',
      change: 'Изменить',
      edit: 'Редактировать',
      remove: 'Удалить',
      value: 'Значение',
      remove_room: 'Удалить номер',
      remove_all_photos: 'Удалить все фото',
      remove_all_photos_ask: 'Удалить все фото?',
      remove_all_photos_descr: 'Вы действительно хотите удалить все фото?',
      remove_room_ask: 'Удалить номер?',
      remove_tariff: 'Удалить тариф',
      remove_tariff_ask: 'Удалить тариф?',
      remove_tariff_name_ask: 'Вы действительно хотите удалить тариф «{name}»?',
      remove_room_name_ask: 'Вы действительно хотите удалить номер «{name}»?',
      duplicate: 'Дублировать',
      choose: 'Выберите из списка',
      add: 'Добавить',
      surname: 'Фамилия',
      name: 'Имя',
      patronym: 'Отчество',
      ivanov: 'Иванов',
      ivan: 'Иван',
      ivanovich: 'Иванович',
      gender: 'Пол',
      male: 'Мужской',
      female: 'Женский',
      adult: 'Взрослый',
      children: 'Ребенок',
      passport_no: '№ Паспорта',
      age_group: 'Возрастная группа',
      person_count: `{count} {count, plural,
        one {гость}
        few {гостя}
        many {гостей}
      }`,
      nights_count: `{count} {count, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }`,
      rooms_busy: `
        забронировано {beds} {beds, plural,
          one {место}
          few {места}
          many {мест}
        } в {rooms} {rooms, plural,
          one {номере}
          few {номерах}
          many {номерах}
        }`,
      rooms_total: `всего {rooms} {rooms, plural,
        one {номер}
        few {номера}
        many {номеров}
      }`,
      rooms_empty: 'Нет номеров данной категории',
      yes: 'Да',
      no: 'Нет',
      check_for_errors: 'Проверьте корректность заполнения введенных данных',
      drop_image_here: 'Перенесите одно или несколько изображений для загрузки',
      choose_file: 'Добавить фото',
      no_photos: 'У Вас пока нет ни одной фотографии',
      rub: 'руб.',
      first_day: 'суток',
      search_string: 'Строка поиска',
      alt_string: 'Название изображения',
      video_manual: 'Видеоинструкция',
      download: 'Скачать',
      from_to: 'С {from} по {to}',
      month: `{month, plural,
        =1 {янв}
        =2 {фев}
        =3 {мар}
        =4 {апр}
        =5 {май}
        =6 {июн}
        =7 {июл}
        =8 {авг}
        =9 {сен}
        =10 {окт}
        =11 {ноя}
        =12 {дек}
      }`,
      date_range: `С {startDay} {startMonth, plural,
        =0 {января}
        =1 {февраля}
        =2 {марта}
        =3 {апреля}
        =4 {мая}
        =5 {июня}
        =6 {июля}
        =7 {августа}
        =8 {сентября}
        =9 {октября}
        =10 {ноября}
        =11 {декабря}
      } {startYear}
      по {endDay} {endMonth, plural,
        =0 {января}
        =1 {февраля}
        =2 {марта}
        =3 {апреля}
        =4 {мая}
        =5 {июня}
        =6 {июля}
        =7 {августа}
        =8 {сентября}
        =9 {октября}
        =10 {ноября}
        =11 {декабря}
      } {endYear}`,
      start_date: 'Дата начала',
      end_date: 'Дата конца',
      disclaimer: 'Дисклеймер',
      promo: 'Промо'
    },
    sogaz: {
      services_selected: `{count, plural,
        one {Выбрана # услуга}
        few {Выбрано # услуги}
        many {Выбрано # услуг}
      }`
    },
    menu: {
      property_management: 'Управление санаторием',
      bookings_dashboard: 'Расписание броней',
      orders_list: 'Список заказов',
      property_description: 'Информация о санатории',
      property_photos: 'Фотографии санатория',
      inventory: 'Номера',
      buildings_and_blocks: 'Корпуса и этажи',
      room_types: 'Типы номеров',
      services: 'Услуги',
      rates_and_rules: 'Тарифные планы',
      rates: 'Тарифы',
      rates_dashboard: 'Шахматка тарифов',
      rates_price_list: 'Прайс-листы',
      rates_mappings: 'Сопоставление тарифов',
      invoices: 'Счета',
      documentation: 'Руководство пользователя',
      integrations: 'Интеграции',
      settings: 'Настройки',
      calc_quotas: 'Расчет квот'
    },
    invoices: {
      no_invoices: 'Сейчас у Вас нет оформленных счетов',
      new_invoice: 'Оформить счет'
    },
    tariff: {
      type: 'Тип тарифного плана',
      clipboard_success: 'Название тарифа скопировано',
      add: 'Добавить тариф',
      extend: 'Продлить',
      all: 'Все тарифы',
      rate: 'Тарифный план',
      source: 'Источник',
      insurant: 'Страхователь',
      new_rate: 'Новый тариф',
      rate_name: 'Название',
      prices: `Ценообразование (за {billing_hour, select,
        day {день}
        other {ночь}
      })`,
      room_type: 'Тип номера',
      status: 'Статус',
      description: 'Описание',
      available_period: 'Период действия',
      effective_period: 'Период действия',
      visibility_period: 'Период отображения',
      stop_sales_period: 'Период остановки продаж',
      not_specified: 'Не задан',
      not_limited: 'Неограничено ',
      services_included: 'Включенные услуги',
      services_not_included: 'Услуги не включены',
      quantity: 'Кол-во',
      periodicity: 'Темп начисления',
      price: 'Стоимость',
      age_group: 'Возрастная группа',
      adult_group: 'Взрослый',
      child_group: 'Ребенок',
      occupation: 'Тип размещения',
      main_occupation: 'Основное',
      additional_occupation: 'Дополнительное',
      single_occupation: '1-местное размещение',
      double_occupation: '2-местное размещение',
      adult_on_main_bed: 'Взрослый на основном месте',
      child_on_main_bed: 'Ребенок на основном месте',
      adult_on_extra_bed: 'Взрослый на дополнительном месте',
      child_on_extra_bed: 'Ребенок на дополнительном месте',
      individually: 'В индивидуальном порядке',
      period_for_booking: 'За бронь',
      period_for_accommodation: 'За проживание',
      period_per_guest: 'За гостя',
      period_per_night: 'За сутки',
      period_per_quest_per_night: 'За гостя в сутки',
      period_for_using: 'За использование',
      period_for_accommodation_per_night: 'За размещение в сутки',
      stay_period: 'Минимальный срок пребывания',
      add_price: 'Добавить',
      periods: `Периоды ценообразования (за {billing_hour, select,
        day {день}
        other {ночь}
      })`,
      type_name: 'Базовый',
      cancellation_policy: 'Правила отмены бронирования',
      release_period: 'Период реализации',
      feed_type_and_meal_option: 'Питание',
      meal_disclaimer: {
        not_specified: 'Например, в низкий сезон возможна замена шведского стола на заказное меню'
      },
      stop_sales: {
        title: 'Стоп продаж (тариф недоступен в эти даты)',
        description: 'Для остановки продаж по тарифу добавьте один или несколько периодов стоп-продаж. Даты периодов стоп-продаж не могут выходить за даты срока действия тарифа'
      },
      restrictions_error: {
        minMessage: 'Значение должно быть от 1 до 365 дней и быть меньше макс. срока',
        maxMessage: 'Значение должно быть от 1 до 365 дней и быть больше мин. срока'
      },
      length_stay_restrictions: {
        title: 'Ограничение срока проживания',
        description: 'Ограничение определяет минимальное/максимальное количество ночей, которое должен прожить гость, в случае, если дата с ограничением попадает в период проживания.',
        dateRangeInput: 'Период действия',
        minLos: {
          title: 'Мин. срок проживания',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение минимальный срок проживания 4. Тогда, при выборе любого\n' +
              'периода, который включает 10 июня, и сроке проживания менее 4\n' +
              'ночей, бронирование номеров данной категории будет\n' +
              'недоступно.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "минимальный срок проживания" :1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "минимальный срок проживания" :0.'
        },
        maxLos: {
          title: 'Макс. срок проживания',
          example: 'Например, если для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение максимальный срок проживания 8, то при выборе любого\n' +
              'периода, который включает 10 июня, и сроке проживания более 8\n' +
              'ночей, бронирование номеров данной категории будет\n' +
              'недоступно.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "максимальный срок проживания" :1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "максимальный срок проживания" :0.'
        }
      },
      advance_booking_restrictions: {
        title: 'Ограничение срока от даты бронирования до даты заезда',
        description: 'Ограничение определяет минимально возможное количество дней между датой бронирования и датой заезда. Бронирование номеров данной категории на указанную дату невозможно менее чем за количество дней, указанное в ограничении.',
        dateRangeInput: 'Период действия',
        minimumAdvanceBooking: {
          title: 'Мин. срок от бронирования до заезда',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение минимальный срок от бронирования до заезда 7. Это значит, что\n' +
              'бронирование номеров данной категории с заездом 10 июня\n' +
              'возможно только до 3 июня включительно. После 3 июня\n' +
              'бронирование на указанную дату невозможно.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "минимальный срок от бронирования до заезда" :1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "минимальный срок от бронирования до заезда" :0.'
        },
        maximumAdvanceBooking: {
          title: 'Макс. срок от бронирования до заезда',
          example: 'Например, если для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение максимальный срок от бронирования до заезда 7, то бронирование\n' +
              'номеров данной категории с заездом 10 июня возможно только\n' +
              'начиная с 3 июня. До 3 июня бронирование на указанную дату\n' +
              'невозможно.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "максимальный срок от бронирования до заезда" :1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "максимальный срок от бронирования до заезда": -1.'
        }
      },
      length_of_stay_arrival_based_restrictions: {
        title: 'Ограничение срока проживания при заезде в указанную дату',
        description: 'Ограничение определяет минимальное\n' +
            'количество ночей, которое должен прожить гость, в случае, если\n' +
            'дата с ограничением приходится на дату заезда.',
        dateRangeInput: 'Период действия',
        minLOSArrival: {
          title: 'Мин. срок проживания при заезде в указанную дату',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение минимальный срок проживания при заезде в указанную дату 4. Тогда, при выборе\n' +
              'любого периода с заездом 10 июня, и сроке проживания менее 4\n' +
              'ночей, бронирование номеров данной категории будет недоступно\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "минимальный срок проживания при заезде в указанную дату": 1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "минимальный срок проживания при заезде в указанную дату": 0.'
        },
        maxLOSArrival: {
          title: 'Макс. срок проживания при заезде в указанную дату',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение максимальный срок проживания при заезде в указанную дату 8. Тогда, при выборе\n' +
              'любого периода c датой заезда 10 июня, и сроке проживания\n' +
              'более 8 ночей, бронирование номеров данной категории будет\n' +
              'недоступно.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "максимальный срок проживания при заезде в указанную дату": 1,\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "максимальный срок проживания при заезде в указанную дату": 0.'
        }
      },
      closed_by_date_restrictions: {
        title: 'Запрет заезда/выезда',
        description: 'Ограничение закрывает указанную дату\n' +
            'для заезда/выезда. Бронирование номеров данной категории\n' +
            'невозможно, если дата заезда/выезда приходится на дату ограничения',
        dateRangeInput: 'Период действия',
        closedToArrival: {
          title: 'Запрет заезда',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение запрет заезда. Это значит, что система не позволит\n' +
              'забронировать номер данной категории с датой заезда 10 июня.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "запрет заезда": "true",\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "запрет заезда": "false".'
        },
        closedToDeparture: {
          title: 'Запрет выезда',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение запрет выезда. Это значит, что система не позволит\n' +
              'забронировать номер данной категории с датой выезда 10 июня.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "запрет выезда": "true",\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "запрет выезда": "false".'
        }
      },
      closed_by_weekday_restrictions: {
        title: 'Запрет заезда/выезда (по будням)',
        description: 'Ограничение закрывает указанную дату\n' +
            'для заезда/выезда. Бронирование номеров данной категории\n' +
            'невозможно, если дата заезда/выезда приходится на дату ограничения',
        dateRangeInput: 'Период действия',
        weekday: {
          title: 'День недели',
          example: 'Выберите день недели',
          list: {
            mon: 'понедельник',
            tue: 'вторник',
            wed: 'среда',
            thu: 'четверг',
            fri: 'пятница',
            sat: 'суббота'
          }
        },
        closedToArrival: {
          title: 'Запрет заезда',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение запрет заезда. Это значит, что система не позволит\n' +
              'забронировать номер данной категории с датой заезда 10 июня.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "запрет заезда": "true",\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "запрет заезда": "false".'
        },
        closedToDeparture: {
          title: 'Запрет выезда',
          example: 'Например: для номеров категории «Стандарт» на 10 июня\n' +
              'установлено ограничение СТD. Это значит, что система не позволит\n' +
              'забронировать номер данной категории с датой выезда 10 июня.\n' +
              'Ограничение устанавливается передачей от менеджера каналов\n' +
              'значения: "запрет выезда": "true",\n' +
              'Ограничение снимается передачей от менеджера каналов\n' +
              'значения: "запрет выезда": "false".'
        }
      }
    },
    tour: {
      type: 'Тип тарифного плана',
      add: 'Добавить тариф',
      all: 'Все тарифы',
      rate: 'Тарифный план',
      source: 'Источник',
      insurant: 'Страхователь',
      new_rate: 'Новый тариф',
      rate_name: 'Название',
      prices: 'Ценообразование (за смену)',
      room_type: 'Тип номера',
      status: 'Статус',
      description: 'Описание',
      available_period: 'Даты заезда и выезда',
      stop_sales_period: 'Период остановки продаж',
      not_specified: 'Не задан',
      not_limited: 'Неограничено ',
      services_included: 'Включенные услуги',
      services_not_included: 'Услуги не включены',
      quantity: 'Кол-во',
      periodicity: 'Темп начисления',
      price: 'Стоимость',
      age_group: 'Возрастная группа',
      adult_group: 'Взрослый',
      child_group: 'Ребенок',
      occupation: 'Тип размещения',
      main_occupation: 'Основное',
      additional_occupation: 'Дополнительное',
      single_occupation: '1-местное размещение',
      double_occupation: '2-местное размещение',
      adult_on_main_bed: 'Взрослый на основном месте',
      child_on_main_bed: 'Ребенок на основном месте',
      adult_on_extra_bed: 'Взрослый на дополнительном месте',
      child_on_extra_bed: 'Ребенок на дополнительном месте',
      individually: 'В индивидуальном порядке',
      period_for_booking: 'За бронь',
      period_for_accommodation: 'За проживание',
      period_per_guest: 'За гостя',
      period_per_night: 'За сутки',
      period_per_quest_per_night: 'За гостя в сутки',
      period_for_using: 'За использование',
      period_for_accommodation_per_night: 'За размещение в сутки',
      stay_period: 'Минимальный срок пребывания',
      add_price: 'Добавить',
      periods: 'Периоды ценообразования (за смену)',
      type_name: 'Смена',
      cancellation_policy: 'Правила отмены бронирования',
      release_period: 'Период реализации'
    },
    package: {
      type: 'Тип тарифного плана',
      add: 'Добавить тариф',
      all: 'Все тарифы',
      rate: 'Тарифный план',
      source: 'Источник',
      insurant: 'Страхователь',
      new_rate: 'Новый тариф',
      rate_name: 'Название',
      prices: 'Ценообразование (за программу)',
      room_type: 'Тип номера',
      status: 'Статус',
      description: 'Описание',
      available_period: 'Период действия',
      stop_sales_period: 'Период остановки продаж',
      not_specified: 'Не задан',
      not_limited: 'Неограничено ',
      services_included: 'Включенные услуги',
      services_not_included: 'Услуги не включены',
      quantity: 'Кол-во',
      periodicity: 'Темп начисления',
      price: 'Стоимость',
      age_group: 'Возрастная группа',
      adult_group: 'Взрослый',
      child_group: 'Ребенок',
      occupation: 'Тип размещения',
      main_occupation: 'Основное',
      additional_occupation: 'Дополнительное',
      single_occupation: '1-местное размещение',
      double_occupation: '2-местное размещение',
      adult_on_main_bed: 'Взрослый на основном месте',
      child_on_main_bed: 'Ребенок на основном месте',
      adult_on_extra_bed: 'Взрослый на дополнительном месте',
      child_on_extra_bed: 'Ребенок на дополнительном месте',
      individually: 'В индивидуальном порядке',
      period_for_booking: 'За бронь',
      period_for_accommodation: 'За проживание',
      period_per_guest: 'За гостя',
      period_per_night: 'За сутки',
      period_per_quest_per_night: 'За гостя в сутки',
      period_for_using: 'За использование',
      period_for_accommodation_per_night: 'За размещение в сутки',
      stay_period: 'Продолжительность лечения',
      add_price: 'Добавить',
      periods: 'Периоды ценообразования (за программу)',
      type_name: 'Программа лечения',
      cancellation_policy: 'Правила отмены бронирования',
      release_period: 'Период реализации'
    },
    tariffs: {
      general: 'Общая информация',
      categories_of_accommodation: 'Категории размещения',
      add_category: 'Добавить категорию размещения',
      change_category: 'Изменить категорию размещения',
      services_included: 'Включенные в тариф услуги',
      price_calculation_rule: 'Правила расчета и ограничения',
      feed_type_and_meal_option: 'Питание',
      save_changes: 'Сохранить изменения',
      available: 'Доступные тарифные планы',
      statuses: {
        created: 'Новый',
        uploaded: 'Предварительный',
        confirmed: 'Подтвержденный',
        unknown: 'Неизвестно'
      },
      base: {
        name: 'Базовый',
        description: 'Стоимость за ночь проживания'
      },
      tour: {
        name: 'Смена',
        description: 'Фиксированные даты заезда и выезда'
      },
      package: {
        name: 'Программа лечения',
        description: 'Фиксированный срок пребывания'
      },
      contract: {
        commission: 'Комиссия, %',
        number: 'Номер договора',
        from_date: 'Дата начала действия'
      },
      cancellation_policy: {
        has_full_refund: 'Полный возврат, если бронирование отменено до даты заезда',
        has_non_refund: 'Полностью невозвратное бронирование',
        rule: `При отмене в срок до {deadline_amount} {billing_hour, select,
          day {{deadline_amount, plural,
            one {дня}
            other {дней}
            }}
          other {{deadline_amount, plural,
            one {ночи}
            other {ночей}
          }}} до даты заезда
          удерживается сумма в размере {penalty_unit, select,
          percent {{penalty_amount} %}
          first_day {стоимости {penalty_amount} суток}
          other {{penalty_amount} руб.}
          }`,
        rule_0_days: `При отмене удерживается сумма в размере {penalty_unit, select,
          percent {{penalty_amount} %}
          first_day {стоимости {penalty_amount} суток}
          other {{penalty_amount} руб.}
          }`,
        policy_penalty_description: 'При выборе расчета комиссии в рублях возврат не может превышать общую стоимость бронирования по данному тарифу',
        max_deadline_amount: 'Максимальный срок отмены - 180 дней до даты заезда',
        non_refund_description: 'При добавлении полностью невозвратного правила отмены, другие условия будут проигнорировны',
        rules: {
          penalty_description: 'удерживается сумма в размере',
          add: 'Добавить правило',
          cancel_description: 'При отмене в срок до',
          deadline_description: `{billing_hour, select,
            day {{deadline_amount, plural,
              one {дня}
              other {дней}
              }}
            other {{deadline_amount, plural,
              one {ночи}
              other {ночей}
            }}
          } `,
          no_refund: 'Невозвратное бронирование'
        }
      },
      price_calculation_rules: {
        self: 'Правила расчета',
        example: 'Пример:',
        base_tips: 'Стоимость по тарифу с 1 по 10 - 1000 руб., с 10 по 20 - 2000 руб.',
        float_tips: 'При выборе расчета с учетом доп. периодов ценообразования стоимость за проживание на даты с 5 по 15 будет считаться по 1000 руб. за сутки в период с 5 по 10 и по 2000 руб. за сутки в период с 11 по 15',
        fixed_tips: 'При выборе расчета на дату заезда стоимость за проживание на даты с 5 по 15 будет считаться по 1000 руб. за сутки',
        float: 'стоимость с учетом доп. периодов ценообразования',
        fixed: 'стоимость на дату заезда'
      },
      release_period: {
        self: 'Период реализации',
        example: 'Пример:',
        tips: 'Период реализации - количество дней/ночей до даты заезда, за которое можно оформить бронирование',
        tips_zero: 'Если указано значение равное 0, то гость может оформить бронирование в день заезда',
        tips_other: 'Если указано значение равное 1, то гость может оформить бронирование не ранее, чем за один день/ночь до даты заезда. Если выбрано значение равное 2 - за два дня/ночи до даты заезда и т.д.',
        count_description: `Бронирование возможно не ранее {count} {billing_hour, select,
          day {{count, plural,
            one {дня}
            other {дней}
            }}
          other {{count, plural,
            one {ночи}
            other {ночей}
            }}
        } до даты заезда`,
        label: 'Бронирование возможно не ранее',
        detail: `{billing_hour, select,
          day {{count, plural,
            one {дня}
            other {дней}
            }}
          other {{count, plural,
            one {ночи}
            other {ночей}
            }}
        } до даты заезда`,
        without_conditions: 'Гость может оформить бронирование в день заезда'
      },
      feed_types: {
        self: 'Тип подачи'
      },
      meal_options: {
        self: 'Тип питания'
      },
      meal_disclaimer: {
        self: 'Дисклеймер'
      },
      manipulation: {
        self: 'Процедуры',
        is_manual: 'Состав и количество процедур определяются индивидуально',
        disclaimer: 'Дисклеймер',
        disclaimer_hint: 'Например, на лечение принимаются только взрослые и дети старше 6 лет',
        quantity: 'Количество процедур в день',
        quantity_string: `{count, plural,
          =0 {Нет лечения}
          one {{count} процедура}
          few {{count} процедуры}
          many {{count} процедур}
        }`,
        quantity_to: `{count, plural,
          =0 {Нет лечения}
          one {{count} процедуры}
          few {до {count} процедур}
          many {до {count} процедур}
        }`,
        quantity_between: `от {from_count} до {to_count} {to_count, plural,
          one {процедуры}
          few {процедур}
          many {процедур}
        }`,
        options: 'Процедуры',
        add: 'Добавить процедуру'
      },
      service: {
        self: 'Услуги',
        disclaimer: 'Дисклеймер',
        disclaimer_hint: 'Например, бассейн работает с 1 июня по 1 октября',
        options: 'Услуги в тарифе ',
        options_quantity: `{count, plural,
          =0 {Нет услуг}
          one {{count} услуга}
          few {{count} услуги}
          many {{count} услуг}
        }`,
        add: 'Добавить услугу'
      },
      promo: {
        self: 'Акции и специальные предложения',
        description: 'Выбрать спец. предложение',
        quantity: 'Размер скидки',
        percent: 'Скидка { percent } %',
        prompt: 'Санатории с акционными тарифами будут доступны в специальном разделе - Акции или На праздники. Размер скидки не влияет на стоимость и применяется для привлечения внимания пользователей',
        disclaimer: 'Краткая выжимка из тарифа, акцент на фуршеты, розыгрыши и веселье'
      }
    },
    price_lists: {
      available: 'Доступные прайс-листы',
      add: 'Добавить прайc-лист',
      generate: 'Сформировать',
      confirm: 'Подтвердить',
      single: 'Прайс-лист',
      empty: 'У Вас нет ни одного выгруженного прайс-листа',
      no_tariffs: 'Нет тарифов соответсвующих условиям фильтрации',
      all: 'Все прайс-листы',
      tips: 'Данные договора необходимы для автоматического подтверждения тарифов со стороны'
    },
    price_list: {
      general: 'Общее описание',
      doc_number: 'Номер договора',
      doc_date: 'Дата договора',
      source_without_confirmation: 'Внимание! Источник { source } не требует дополнительного подтверждения.',
      confirmed: 'Прайс-лист подтвержден.',
      press_to_confirm: 'Если данные Приложения корректны, нажмите кнопку «Подтвердить».',
      uploaded: 'Прайс-лист ожидает подтверждения источника. Тарифы не доступны для редактирования.',
      successfully_created: 'Приложение {number} к договору №{doc_number} от {date} г. успешно сформировано. ',
      to_start_downloading_press: 'Для начала загрузки нажмите на ',
      link: 'ссылку',
      and_save_file: ' и сохраните файл.',
      could_be_destroy: 'В случае если прайс-лист был создан ошибочно, вы можете ',
      destroy: 'удалить его.',
      could_be_change: 'Для внесения изменений в тарифы  ',
      revoke_confirmation: 'отмените подтверждение прайс-листа.'
    },
    rates: {
      self: 'Категории размещения',
      add: 'Добавить категорию',
      price: 'Стоимость проживания',
      price_details: `Стоимость проживания за {tariffType, select,
        base {одну ночь}
        tour {смену}
        package {программу}
        }`,
      count: 'Плотность размещения',
      description: `{ageGroup, select,
        child {Ребенок}
        adult {Взрослый}
      } {occupation, select,
        main {на основном месте {count, plural,
          =0 {{ageGroup, select,
            child {}
            adult {с подселением}
          }}
          one {при 1-но местном размещении}
          few {при {count}-х местном размещении}
          many {при {count}-и местном размещении}
        }}
        extra {на дополнительном месте}
        no {без места}
      }
      {ageGroup, select,
        adult {}
        child {({from} - {to} лет)}
      }`,
      description_main: `{age_group, select,
        adult {Взрослый}
        child {Ребенок}
      } {occupation, select,
        main {на основном месте}
        extra {на дополнительном месте}
      }`,
      description_no_place_child: 'Ребенок без места',
      description_extra: `{count, plural,
        =0 {С подселением}
        one {При 1-но местном размещении}
        few {При {count}-х местном размещении}
        many {При {count}-и местном размещении}
      }`,
      empty: 'Необходимо добавить одну или несколько категорий размещения, согласно которым будет расчитываться стоимость проживания по данному тарифу',
      include_error: 'Вы уже добавили категорию размещения с аналогичными параметрами размещения',
      adult_category_empty: 'Отстуствует категория размещения для взрослого',
      age_period_inter_error: 'Возрастной период для детей не должен пересекаться в рамках одной категории размещения',
      age_period_equal_error: 'Некорректно заполнен возрастной период'
    },
    rates_tips: {
      room_description: 'Тип номера: {room}',
      rooms_count: 'Доступно номеров данного типа: {count}',
      room_seats: `Количество мест: {beds, plural,
        one {одно основное место}
        few {{beds} основных места}
        many {{beds} основных мест}
      } {extraBeds, plural,
        =0 {}
        one {и одно дополнительное место}
        few {и {extraBeds} дополнительных места}
        many {и {extraBeds} дополнительных мест}
      }`,
      description: `Стоимость проживания за {tariffType, select,
        base {одну ночь}
        tour {смену}
        package {программу}
        }
        для {ageGroup, select,
        child {ребенка от {from} до {to} {to, plural,
          one {года}
          few {лет}
          many {лет}
        }}
        adult {взрослого}
      } {occupation, select,
        main {на основном месте {count, plural,
          =0 {с подселением}
          one {при 1-но местном размещении}
          few {при {count}-х местном размещении}
          many {при {count}-и местном размещении}
        }}
        extra {на дополнительном месте}
        no {без места}
      } {price, select,
        0 {}
        other { {price} {currency}}
      }`
    },
    occupation: {
      self: 'Тип размещения',
      main: 'Основное место',
      extra: 'Дополнительное место',
      single: '1-местное размещение',
      full: '{beds}-местное размещение',
      count: `{count, plural,
        =0 { Независимо от кол-ва человек в номере}
        one {При 1-но местном размещении}
        few {При {count}-х местном размещении}
        many {При {count}-и местном размещении}
      }`
    },
    age_group: {
      self: 'Возростная группа',
      from: 'от',
      to: 'до',
      adult: 'Взрослый',
      adult_main: 'Взрослый на основном месте',
      adult_extra: 'Взрослый на дополнительном месте',
      child_main: 'Ребенок на основном месте',
      child_extra: 'Ребенок на дополнительном месте',
      child: 'Ребенок'
    },
    age_period: {
      child: `Ребенок от {from} до {to} {to, plural,
        one {года}
        few {лет}
        many {лет}
      }`,
      from: 'от',
      to: 'до',
      years: 'лет'
    },
    stay_period: {
      not_seted: 'Номера данного типа доступны на любой срок пребывания',
      seted: `Номера данного типа будут доступны при бронировании на срок от {days} {billing_hour, select,
        day {{days, plural,
          one {день}
          few {дня}
          many {дней}
          }}
        other {{days, plural,
          one {ночь}
          few {ночи}
          many {ночей}
          }}
      }`,
      min_days_count: `{billing_hour, select,
        day {{count, plural,
          one {день}
          few {дня}
          many {дней}
          }}
        other {{count, plural,
          one {ночь}
          few {ночи}
          many {ночей}
          }}
      }`,
      min_days_with_count: `{count} {billing_hour, select,
        day {{count, plural,
          one {день}
          few {дня}
          many {дней}
          }}
        other {{count, plural,
          one {ночь}
          few {ночи}
          many {ночей}
          }}
      }`
    },
    quotas: {
      self: 'Период ограничения доступности номеров',
      quantity: 'Кол-во',
      empty: 'При необходимости добавьте один или несколько периодов ограничения доступности номерного фонда. Для остановки продаж в указанный период необходимо указать значение квоты 0',
      add: 'Добавить ограничение',
      date_not_defined: 'Не задан',
      rooms_count: `{count} {count, plural,
        one {номер}
        few {номера}
        many {номеров}
      }`,
      rooms_count_only_label: `{count, plural,
        one {номер}
        few {номера}
        many {номеров}
      }`,
      available_rooms: `Всего {count, plural,
        one {доступен}
        other {доступно}
        }
        {count} {count, plural,
        one {номер}
        few {номера}
        many {номеров}
        } данного типа`
    },
    periods: {
      caption: 'Периоды ценообразования',
      self: 'Дополнительные периоды ценообразования',
      empty: 'При необходимости добавьте один или несколько периодов ценообразования. Например для регулирования цен в высокий или низкий сезоны',
      prices: 'Цены',
      add: 'Добавить период',
      delete: 'Удалить периоды',
      start_date: 'Период действия',
      rates: 'Цены',
      date_not_defined: 'Не задан',
      rate: {
        priceError: 'Бесплатное размещение доступно только для детей'
      }
    },
    services: {
      general: 'Общие',
      foodservice: 'Питание',
      medical_and_rehabilitation: 'Медицина',
      service: 'Название',
      price: 'Стоимость',
      description: 'Описание',
      type: 'Категория',
      add: 'Добавить услугу',
      new: 'Новая услуга'
    },
    dashboard: {
      groups_quotas_btn: 'Групповое изменение квот',
      show_all_rooms: 'Показать все номера',
      hide_all_rooms: 'Скрыть все номера',
      show_one_room: 'Показать номера',
      hide_one_room: 'Скрыть номера',
      new_booking: 'Новая бронь',
      room_description: 'Информация о бронировании',
      booking_details: 'Информация о бронировании',
      dates: 'Даты бронирования',
      room_type: 'Тип комнаты',
      rate: 'Тип размещения',
      tariff: 'Тариф',
      guest_details: 'Информация о гостях',
      check_in_date: 'Дата заезда',
      check_out_date: 'Дата выезда',
      cancel_booking: 'Отменить бронь',
      confirm_booking: 'Подтвердить бронь',
      booking_dates: 'Даты',
      booking_room_type: 'Тип номера',
      booking_passport: 'Пасспорт',
      booking_tariff: 'Тариф',
      booking_amount: 'Сумма',
      booking_source: 'Источник',
      not_found: 'На данный период нет доступных мест для заселения',
      available_seats: 'Доступно { main } основных, { extra } допольнительных мест',
      beds: 'Основных мест',
      extra_beds: 'Дополнительных мест',
      booking_code: 'Групповая заявка',
      booking_expires_at: 'Бронь истекает через { time }',
      total: `Всего {total} {total, plural,
        zero {Нет номеров данной категории}
        one {номер}
        few {номера}
        other {номеров}
      }`,
      all_orders: 'К списку заказов',
      quota: {
        self: 'Квота по источникам',
        available: 'Свободно',
        booked: 'Занято',
        allotment: 'Квота',
        edit: {
          self: 'Изменить квоту',
          range_error: 'Не выбран диапазон',
          end_range_error: 'Не выбран конечная дата. Если это тот же день, выберите его повторно',
          quota: {
            self: 'Квота',
            disclaimer: 'При изменении квоты, доступные номера пересчитывается автоматически:',
            formula: 'Доступные = Квота – Занятые',
            limit_error: 'Квота ({sourceName}) превышает допустипоме значение ({limit})'
          },
          availability: {
            self: 'Доступность',
            disclaimer: 'При изменении количества доступных номеров, квота пересчитывается автоматически:',
            formula: 'Квота = Доступные + Занятые'
          }
        }
      }
    },
    reservations: {
      booking_code: 'Бронирование №{code}'
    },
    hotel: {
      property_description: 'Информация о санатории',
      object_type: 'Тип объекта',
      feed: 'Питание',
      feedType_didnt_choosed: 'Не выбран тип питания, который используется в Вашем санатории',
      meal_options_didnt_choosed: 'Не выбран тип подачи, который используется в Вашем санатории',
      location_types_didnt_choosed: 'Выберите один или несколько особенностей расположения, которому соответсвует Ваш санаторий',
      accommodation_features_didnt_choosed: 'Выберите одну или несколько особенностей размещения, который имеются в Вашем санатории',
      treatment_factors_didnt_choosed: 'Не выбрано ни одного лечебного фактора, которые используются в Вашем санатории',
      treatment_profiles_didnt_choosed: 'Не выбрано ни одного профиля лечения, на котором специализируется Ваш санаторий',
      object_type_didnt_choosed: 'Не выбран тип объекта, которому соответсвует Ваш санаторий',
      feedType: 'Тип питания',
      night: 'Ночь',
      day: 'День',
      meal_options: 'Тип подачи',
      address: 'Адрес',
      before: 'До',
      after: 'После',
      name: 'Название',
      email: 'Электронная почта',
      location_types: 'Особенности расположения',
      accommodation_features: 'Особенности размещения',
      phone: 'Контактный телефон',
      description: 'Описание',
      website: 'Сайт',
      coordinates: 'Координаты',
      treatment_profiles: 'Профили лечения',
      treatment_factors: 'Лечебные факторы',
      add_specialization_hint: 'Выберите один или несколько профилей лечения, на которых специализируется Ваш санаторий',
      star: 'Количество звезд',
      star_description: 'Если к Вашему объекту звездность не применима, оставьте значение 0',
      add_photo: 'Добавить фото',
      checkout_time_and_age_limits: 'Расчетный час и возрастные ограничения',
      calculate_period_in: 'Расчетный час',
      accommodation: 'Проживание',
      age_limit: 'Возрастные ограничения',
      checkin_time_after: 'Время заезда',
      checkout_time_before: 'Время выезда',
      children_age_any: 'Разрешается проживание детей любого возраста',
      children_age_from: `Детям от {years} {years, plural,
        one {года}
        few {лет}
        other {лет}
      }`,
      amenities_and_services: 'Удобства и услуги',
      add_amenities_hint: 'Выберите из списка доступные услугу и сервисы, которые предоставляет Ваш санаторий гостям',
      amenities_didnt_choosed: 'Вы не выбрали ни одной услуги или сервиса',
      contact_information: 'Контактная информация',
      add_email: 'Добавить еще один адрес',
      add_phone: 'Добавить еще один телефон',
      additional_communication_channels: 'Другие виды связи',
      general_information: 'Общая информация',
      payment_details: 'Варианты оплаты',
      card_types_accepted: 'Принимаются следующие виды банковский карт:',
      use_for_notification: 'Использовать для оповещения о новых бронированиях',
      payment_by_cash: 'Оплата на стойке регистрации',
      payment_by_card: 'Безналичный расчет при бронировании',
      by_prepayment: 'Предоплата',
      payment_by_prepayment: 'Предоплата разрешена',
      payment_by_card_not_accepted: 'Банковские карты к оплате не принимаются',
      payment_alowed: 'Разрешается',
      payment_not_alowed: 'Оплата данным методом невозможна',
      settings: {
        billing_hour: 'Расчет периода пребывания в ',
        night: 'ночах',
        day: 'днях',
        billing_hour_hint: 'Для изменения типа расчета, просьба обратиться в Службу поддержки',
        source_with_confirmation: 'Источник требует подтверждения тарифа',
        source_without_confirmation: 'Источник не требует подтверждения тарифа'
      },
      currency: 'Валюта'
    },
    buildings: {
      self: 'Корпуса и этажи',
      name: 'Название',
      floors: 'Этажей',
      add: 'Добавить корпус',
      name_of_the_block: 'Название корпуса'
    },
    room_types: {
      general: 'Общее',
      self: 'Типы номеров',
      name: 'Название',
      beds: 'Основных мест',
      extra_beds: 'Дополнит. мест',
      multiple_occupation_allowed: 'Возможность заселения по разным броням',
      add: 'Добавить номер',
      sngl_std: 'Одноместный, стандартный',
      room_description: 'Редактирование номера',
      modal_description: 'Укажите суммарное число номеров этого типа',
      modal_room_counts: 'Всего номеров: ',
      available_beds: 'Доступные спальные места',
      photos: 'Фотографии номера',
      delete_photos: 'Удалить все',
      area: 'Площадь',
      area_full: 'Общая площадь',
      area_placeholder: 'кв. м',
      no_photo: 'Нет фото',
      change_the_quantity: 'Изменить количество',
      rm_count: 'Комнат',
      description: 'Описание',
      room_count: `{rooms} {rooms, plural,
        zero: {комнат}
        one {комната}
        few {комнаты}
        other {комнат}
      }`,
      capacity: `{capacity} {capacity, plural,
        zero: {номеров}
        one {номер}
        few {номерa}
        other {номеров}
      }`,
      beds_count: `{beds} {beds, plural,
        zero: {основных мест}
        one {основное место}
        few {основных места}
        other {основных мест}
      }`,
      extra_beds_count: `{beds} {beds, plural,
        zero: {дополнит. мест}
        one {дополнит. место}
        few {дополнит. места}
        other {дополнит. мест}
      }`
    },
    rooms: {
      room_number: '№ Комнаты',
      floor: 'Этаж',
      block: 'Корпус',
      room_type: 'Тип номера',
      add: 'Добавить номер',
      not_specified: 'Не задан'
    },
    orders: {
      add: 'Новый заказ',
      room_type_and_tariff: 'Тип номера и тариф',
      guests: 'Гости',
      price: 'Цена',
      list: 'Список заказов',
      more_about_order: 'Подробнее о заказе',
      comments: 'Комментарии',
      order_number: 'Заказ №{code}',
      expired_at: 'Время истечения бронирования',
      statuses: {
        booked: 'Забронирован',
        confirmed: 'Подтвержден',
        confirmed_description: '(Требуется расшифровка)',
        cancelled: 'Отменен',
        unknown: 'Неизвестно'
      },
      types: {
        insurance: 'СК',
        hotel: 'Санаторий'
      },
      status: 'Статус',
      filter: 'Фильтр',
      type: 'Источник',
      check_in: 'Дата заезда',
      check_in_data: 'Дата заезда после',
      check_out: 'Дата выезда',
      check_out_data: 'Дата выезда до',
      hotel: 'Санаторий',
      manager: 'Менеджер',
      managers_placeholder: 'Начните вводить фамилию или имя',
      hotels_placeholder: 'Начните вводить название санатория',
      guestName: 'Имя гостя',
      source: 'Источник',
      last_changed: 'Время изм.',
      travellers_count: `{count} {count, plural,
        one {человек}
        few {человека}
        many {человек}
      }`,
      email: 'Электронная почта',
      phone: 'Телефон',
      code: '№',
      clear: 'Сброс',
      payment_method: {
        bank_transfer: 'безналичными',
        cash: 'наличными',
        total: 'всего'
      }
    },
    order: {
      download: {
        button: 'Отчет XLSX',
        default: 'Стандартный отчет',
        sogaz: 'Отчет по форме АО "СОГАЗ"'
      },
      slot: {
        rates_error: 'Вы не задали категории размещения для выбранных условий расселения'
      }
    },
    ui: {
      duration: `{value} {unit, select,
        day {{value, plural,
          one {день}
          few {дня}
          many {дней}
        }}
        night {{value, plural,
          one {ночь}
          few {ночи}
          many {ночей}
        }}
      }`
    }
  },
  en: {
    ui: {
      duration: `{value} {unit, select,
        day {{value, plural,
          one {day}
          other {days}
        }}
        night {{value, plural,
          one {night}
          other {nights}
        }}
      }`
    },
    shared: {
      menu_header: 'EXTRANET',
      downloadLetter: 'Download letter',
      login_title: 'Property management',
      email: 'E-mail',
      email_placeholder: 'Your email',
      password: 'Password',
      password_placeholder: 'Your password',
      login: 'Login',
      logout: 'Logout',
      cancel: 'Cancel',
      cancel_do: 'Cancel',
      close: 'Close',
      value: 'Value',
      confirm: 'Save',
      change: 'Change',
      edit: 'Edit',
      remove: 'Remove',
      remove_all_photos: 'Delete all photos',
      remove_all_photos_ask: 'Delete all photos?',
      remove_all_photos_descr: 'Are you sure you want to delete all photos?',
      remove_room: 'Remove room',
      remove_tariff: 'Remove tariff',
      duplicate: 'Duplicate',
      choose: 'Choose..',
      add: 'Add',
      surname: 'Surname',
      name: 'Name',
      patronym: 'Patronym',
      ivanov: 'Ivanov',
      ivan: 'Ivan',
      ivanovich: 'Ivanovich',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      adult: 'Adult',
      children: 'Children',
      passport_no: 'Passport No.',
      age_group: 'Age group',
      person_count: `{count} {count, plural,
        one {guest}
        other {guests}
      }`,
      nights_count: `{count} {count, plural,
        one {night}
        other {nights}
      }`,
      rooms_busy: `
        booked {beds} {beds, plural,
          one {seat}
          other {seats}
        } in {rooms} {rooms, plural,
          one {room}
          other {rooms}
        }`,
      rooms_total: `total {rooms} {rooms, plural,
        one {room}
        other {rooms}
      }`,
      rooms_empty: 'No rooms in this category',
      yes: 'Yes',
      no: 'No',
      check_for_errors: 'Check the entered data for errors',
      drop_image_here: 'Drop one or more images to upload',
      choose_file: 'Add photo',
      no_photos: 'You did not upload any photos yet',
      rub: 'rub.',
      first_day: 'days',
      search_string: 'Search',
      alt_string: 'Picture name',
      video_manual: 'Video manual',
      download: 'Download',
      from_to: 'From {from} to {to}',
      month: `{month, plural,
        =1 {Jan}
        =2 {Feb}
        =3 {Mar}
        =4 {Apr}
        =5 {May}
        =6 {Jun}
        =7 {Jul}
        =8 {Aug}
        =9 {Sep}
        =10 {Oct}
        =11 {Nov}
        =12 {Dec}
      }`,
      disclaimer: 'Disclaimer',
      promo: 'Promo'
    },
    menu: {
      property_management: 'Property management',
      bookings_dashboard: 'Bookings dashboard',
      property_description: 'Property description',
      property_photos: 'Photos',
      inventory: 'Inventory',
      buildings_and_blocks: 'Buildings and blocks',
      room_types: 'Room types',
      services: 'Services',
      rates_and_rules: 'Rates and rules',
      rates: 'Rates',
      rates_dashboard: 'Rates dashboard',
      rates_price_list: 'Price lists',
      rates_mappings: 'Rate comparison',
      invoices: 'Invoices',
      orders_list: 'Orders',
      documentation: "User's manual"
    },
    invoices: {
      no_invoices: 'You have no available invoices at the moment.',
      new_invoice: 'New invoice'
    },
    tariff: {
      type: 'Type of rate',
      add: 'Add rate',
      all: 'Rates',
      rate: 'Rate',
      source: 'Source',
      insurant: 'Insurant',
      new_rate: 'New rate',
      rate_name: 'Rate name',
      prices: `Price formation (per {billing_hour, select,
        day {day}
        other {night}
      })`,
      room_type: 'Room type',
      status: 'Status',
      description: 'Description',
      available_period: 'Available period',
      effective_period: 'Effective period',
      visibility_period: 'Visibility period',
      stop_sales_period: 'Stop sales period',
      not_specified: 'Not specified',
      not_limited: 'Not limited',
      services_included: 'Services included',
      services_not_included: 'none',
      quantity: 'Quantity',
      periodicity: 'Periodicity',
      price: 'Price',
      age_group: 'Age group',
      adult_group: 'Adult',
      child_group: 'Child',
      occupation: 'Accommodation type',
      main_occupation: 'Main bed',
      additional_occupation: 'Extra bed',
      single_occupation: 'Single accommodation',
      double_occupation: 'Double accommodation',
      adult_on_main_bed: 'Adult on main bed',
      child_on_main_bed: 'Child on main bed',
      adult_on_extra_bed: 'Adult on extra bed',
      child_on_extra_bed: 'Child on extra bed',
      individually: 'Individually',
      period_for_booking: 'For booking',
      period_for_accommodation: 'For accommodation',
      period_per_guest: 'Per guest',
      period_per_night: 'Per night',
      period_per_quest_per_night: 'Per guest per night',
      period_for_using: 'For using',
      period_for_accommodation_per_night: 'For accommodation per night',
      stay_period: 'Minimum stay period',
      add_price: 'Add',
      periods: `Pricing periods (for {billing_hour, select,
        day {day}
        other {night}
      })`,
      type_name: 'Base',
      cancellation_policy: 'Cancellation policies',
      release_period: 'Release period',
      feed_type_and_meal_option: 'Feeding',
      meal_disclaimer: {
        not_specified: 'For example, buffet menu can be replaced by custom menu in low season'
      },
      stop_sales: {
        title: 'Stop sales',
        description: 'To stop selling at a rate, add one or more stop selling periods. Dates of stop-sales periods cannot go beyond the dates of the tariff validity period'
      }
    },
    tour: {
      type: 'Type of rate',
      add: 'Add rate',
      all: 'Rates',
      rate: 'Rate',
      source: 'Source',
      insurant: 'Insurant',
      new_rate: 'New rate',
      rate_name: 'Rate name',
      prices: 'Pricing (per shift)',
      room_type: 'Room type',
      status: 'Status',
      description: 'Description',
      available_period: 'Available period',
      stop_sales_period: 'Stop sales period',
      not_specified: 'Not specified',
      not_limited: 'Not limited',
      services_included: 'Services included',
      services_not_included: 'none',
      quantity: 'Quantity',
      periodicity: 'Periodicity',
      price: 'Price',
      age_group: 'Age group',
      adult_group: 'Adult',
      child_group: 'Child',
      occupation: 'Accommodation type',
      main_occupation: 'Main bed',
      additional_occupation: 'Extra bed',
      single_occupation: 'Single accommodation',
      double_occupation: 'Double accommodation',
      adult_on_main_bed: 'Adult on main bed',
      child_on_main_bed: 'Child on main bed',
      adult_on_extra_bed: 'Adult on extra bed',
      child_on_extra_bed: 'Child on extra bed',
      individually: 'Individually',
      period_for_booking: 'For booking',
      period_for_accommodation: 'For accommodation',
      period_per_guest: 'Per guest',
      period_per_night: 'Per night',
      period_per_quest_per_night: 'Per guest per night',
      period_for_using: 'For using',
      period_for_accommodation_per_night: 'For accommodation per night',
      stay_period: 'Minimum stay period',
      add_price: 'Add',
      periods: 'Pricing periods (per shift)',
      type_name: 'Shift',
      cancellation_policy: 'Cancellation policies',
      release_period: 'Release period'
    },
    package: {
      type: 'Type of rate',
      add: 'Add rate',
      all: 'Rates',
      rate: 'Rate',
      source: 'Source',
      insurant: 'Insurant',
      new_rate: 'New rate',
      rate_name: 'Rate name',
      prices: 'Pricing (per program)',
      room_type: 'Room type',
      status: 'Status',
      description: 'Description',
      available_period: 'Available period',
      stop_sales_period: 'Stop sales period',
      not_specified: 'Not specified',
      not_limited: 'Not limited',
      services_included: 'Services included',
      services_not_included: 'none',
      quantity: 'Quantity',
      periodicity: 'Periodicity',
      price: 'Price',
      age_group: 'Age group',
      adult_group: 'Adult',
      child_group: 'Child',
      occupation: 'Accommodation type',
      main_occupation: 'Main bed',
      additional_occupation: 'Extra bed',
      single_occupation: 'Single accommodation',
      double_occupation: 'Double accommodation',
      adult_on_main_bed: 'Adult on main bed',
      child_on_main_bed: 'Child on main bed',
      adult_on_extra_bed: 'Adult on extra bed',
      child_on_extra_bed: 'Child on extra bed',
      individually: 'Individually',
      period_for_booking: 'For booking',
      period_for_accommodation: 'For accommodation',
      period_per_guest: 'Per guest',
      period_per_night: 'Per night',
      period_per_quest_per_night: 'Per guest per night',
      period_for_using: 'For using',
      period_for_accommodation_per_night: 'For accommodation per night',
      add_price: 'Add',
      stay_period: 'Duration of treatment',
      periods: 'Pricing periods (per program)',
      type_name: 'Treatment program',
      cancellation_policy: 'Cancellation policies',
      release_period: 'Release period'
    },
    tariffs: {
      general: 'General',
      categories_of_accommodation: 'Categories of accommodation',
      add_category: 'Add category',
      change_category: 'Change category',
      services_included: 'Services included',
      price_calculation_rule: 'Calculation rules and restrictions',
      save_changes: 'Save changes',
      available: 'Available tariffs',
      feed_type_and_meal_option: 'Feeding',
      statuses: {
        created: 'New',
        uploaded: 'Draft',
        confirmed: 'Confirmed',
        unknown: 'Unknown'
      },
      cancellation_policy: {
        has_full_refund: 'Full refund',
        has_non_refund: 'Non refund',
        rule: `Penalty is equal to {penalty_unit, select,
            percent {{penalty_amount} %}
            first_day {the price of {penalty_amount} days of stay}
            other {{penalty_amount} rub.}
          }
          when cancelled in {deadline_amount} {deadline_amount, plural,
            one {day}
            other {days}
            }
          before arrival`,
        policy_penalty_description: 'When choosing the penalty in rubles, the max value cannot be exceed of the order total amount',
        max_deadline_amount: 'Max delay value is 180 days before arrival',
        non_refund_description: 'When non refund rule was set, another rules will be rejected',
        rules: {
          penalty_description: 'before arrival, penalty',
          add: 'Add rule',
          cancel_description: 'When cancelled in',
          deadline_description: `{deadline_amount, plural,
            one {day}
            other {days}
            }
          `
        }
      },
      price_calculation_rules: {
        self: 'Calculation rules',
        example: 'Example:',
        base_tips: 'There is a tariff with prices from 1 to 10 - 1000 rub., from 10 to 20 - 2000 rub.',
        float_tips: `If Price including additional availability periods will be selected,
            order's total amount in stay period from 5 to 15 will be equal to 1000 rub. per night from 5 to 10 and
             2000 rub. per night from 11 to 15`,
        fixed_tips: `If Price on arrival date will be selected,
            order's total amount will be equal to 1000 rub. per night`,
        float: 'price including additional availability periods',
        fixed: 'price on arrival date'
      },
      base: {
        name: 'Base',
        description: 'Cost per night'
      },
      tour: {
        name: 'Shift',
        description: 'Fixed dates of arrival and departure'
      },
      package: {
        name: 'Treatment program',
        description: 'Fixed stay'
      },
      release_period: {
        self: 'Release period',
        example: 'Example:',
        tips: 'Release period - quantity of nights when advance booking is possible',
        tips_zero: 'If quantity of nights "0" is selected, the guest can make a reservation on the day of arrival',
        tips_other: 'If quantity of nights "1" is selected, the guest can make a reservation not earlier then 1 day before the arrival date. ' +
          'If quantity of nights "2" is selected - 2 days before the arrival date and etc.',
        count_description: `Advance booking deadline {count} {billing_hour, select,
          day {{count, plural,
            one {day}
            other {days}
            }}
          other {{count, plural,
            one {night}
            other {nights}
            }}
        } before arrival date`,
        label: 'Advance booking deadline',
        detail: `{billing_hour, select,
          day {{count, plural,
            one {day}
            other {days}
            }}
          other {{count, plural,
            one {night}
            other {nights}
            }}
        } before arrival date`,
        without_conditions: 'The guest can make a reservation on the arrival date'
      },
      feed_types: {
        self: 'Feed types'
      },
      meal_options: {
        self: 'Meal options'
      },
      meal_disclaimer: {
        self: 'Disclaimer'
      },
      manipulation: {
        self: 'Manipulations',
        is_manual: 'The composition and number of manipulations are determined individually',
        disclaimer: 'Disclaimer',
        disclaimer_hint: 'For example, only adults and children over 6 years old are accepted for treatment',
        quantity: 'Procedures per day',
        quantity_string: `{count, plural,
          =0 {No cure}
          one {{count} manipulation}
          few {{count} manipulations}
          many {{count} manipulations}
        }`,
        quantity_to: `{count, plural,
          =0 {No cure}
          one {{count} manipulation}
          few {до {count} manipulations}
          many {до {count} manipulations}
        }`,
        quantity_between: `From {from_count} to {to_count} {to_count, plural,
          one {manipulation}
          few {manipulations}
          many {manipulations}
        }`,
        options: 'Manipulations',
        add: 'Add manipulation'
      },
      service: {
        self: 'Services',
        disclaimer: 'Disclaimer',
        disclaimer_hint: 'For example, the pool is open from June 1 to October 1',
        options: 'Services included into tariff ',
        options_quantity: `{count, plural,
          =0 {No services}
          one {{count} service}
          few {{count} services}
          many {{count} services}
        }`,
        add: 'Add service'
      },
      promo: {
        self: 'Special promos',
        description: 'Select promo',
        promo: 'Tariff by sale',
        new_year: 'New year\'s promo',
        covid: 'Rehabilitation after COVID',
        quantity: 'Sale\'s quantity',
        prompt: 'Sanatoriums with promotional rates will be available in a special section - Promotions or On holidays. The size of the discount does not affect the cost and is used to attract the attention of users',
        disclaimer: 'A brief summary of the tariff, emphasis on buffets, sweepstakes and fun'
      }
    },
    price_lists: {
      available: 'Available price lists',
      add: 'Add new price list',
      generate: 'Generate',
      confirm: 'Confirm',
      single: 'Price list',
      empty: 'You have no unloaded price list',
      no_tariffs: 'You do not have tariffs complying with filtering conditions',
      all: 'All price lists',
      tips: 'Document data will be use for automatically search rates at '
    },
    price_list: {
      general: 'General',
      doc_number: 'Document No',
      doc_date: 'Document date at',
      source_without_confirmation: 'Attention! Source { source } is without confirmation',
      confirmed: 'Price list is confirmed',
      press_to_confirm: 'If the price list is correct, please press Confirm button',
      uploaded: 'Price list is waiting confirmation from source. Tariffs are not editable',
      successfully_created: 'Annex #{number} to contract #{doc_number} from {date} was successfully created.',
      to_start_downloading_press: 'To start download press the ',
      link: 'link',
      and_save_file: ' and save the file',
      could_be_destroy: 'If the price list was created with errors, you could ',
      destroy: 'destroy it.',
      could_be_change: 'If you need to change tariffs, you could ',
      revoke_confirmation: 'Revoke confirmation from this price list.'
    },
    rates: {
      self: 'Rates',
      add: 'Add rate',
      price: 'Price',
      price_details: `Cost of living {tariffType, select,
        base {per night}
        tour {per shift}
        package {for the program}
        }`,
      count: 'Placement density',
      description: `{ageGroup, select,
        child {Child}
        adult {Adult}
      } {occupation, select,
        main {on the main bed {count, plural,
          =0 {{ageGroup, select,
            child {}
            adult {with shares}
          }}
          one {single occupancy}
          other {{count} occupancy}
        }}
        extra {on the extra bed}
        no {without place}
      }
      {ageGroup, select,
        adult {}
        child {({from} - {to} age)}
      }`,
      description_main: `{age_group, select,
        adult {Adult}
        child {Child}
      } {occupation, select,
        main {on the main bed }
        extra {on the extra bed}
      }`,
      description_extra: `{count, plural,
        =0 {with shares}
        one {single occupancy}
        other {{count} occupancy}
      }`,
      empty: 'It is necessary to add one or several categories of accommodation, according to which the cost of living at this tariff will be calculated',
      include_error: 'You have already added a placement category with similar placement options.',
      adult_category_empty: 'There is no adult accommodation category',
      age_period_inter_error: "The age period for children should't overlap within the same placement category.",
      age_period_equal_error: 'The age period is filled in incorrectly'
    },
    rates_tips: {
      room_description: 'Type of number: {room}',
      rooms_count: 'Available rooms of this type: {count}',
      room_seats: `Number of seats: {beds, plural,
        one {one main bed}
        other {{beds} main beds}
      } {extraBeds, plural,
        =0 {}
        one {and one extra bed}
        other {and {extraBeds} extra beds}
      }`,
      description: `Cost of living {tariffType, select,
        base {per one night}
        tour {per shift}
        package {for the program}
        }
        for {ageGroup, select,
        child {child from {from} to {to} {to, plural,
          one {year}
          other {years}
        }}
        adult {adult}
      } {occupation, select,
        main {on the main bed {count, plural,
          =0 {with shares}
          one {single accommodation}
          other {{count} accommodation}
        }}
        extra {on the extra bed}
      } {price, select,
        0 {}
        other { {price} rub.}
      }`
    },
    occupation: {
      self: 'Occupation',
      main: 'Main bed',
      extra: 'Extra bed',
      single: 'Single accommodation',
      full: '{beds}-bed accommodation',
      count: `{count, plural,
        =0 {Regardless of the number of people in the room}
        one {Single accommodation}
        other {{count} accommodation}
      }`
    },
    age_group: {
      self: 'Age group',
      from: 'from',
      to: 'to',
      adult: 'Adult',
      child: 'Child'
    },
    age_period: {
      child: `Child from {from} to {to} {to, plural,
        one {year}
        other {years}
      }`,
      from: 'from',
      to: 'to',
      years: `{years} {years, plural,
        one {year}
        other {years}
      }`
    },
    stay_period: {
      not_seted: 'This room type is available for any period of stay',
      seted: `This room type will be available for reservations for a period of {days} {billing_hour, select,
        day {{days, plural,
          one {day}
          other {days}
          }}
        other {{days, plural,
          one {night}
          other {nights}
          }}
      }`,
      min_days_count: `{billing_hour, select,
        day {{count, plural,
          one {day}
          other {days}
          }}
        other {{count, plural,
          one {night}
          other {nights}
          }}
      }`,
      min_days_with_count: `{count} {billing_hour, select,
        day {{count, plural,
          one {day}
          other {days}
          }}
        other {{count, plural,
          one {night}
          other {nights}
          }}
      }`
    },
    quotas: {
      self: 'Additional quotation period',
      quantity: 'Quantity',
      empty: 'If necessary, add one or more quotation periods. To stop sales in the specified period, you must specify the value of the quota 0',
      add: 'Add quota',
      date_not_defined: 'Not defined',
      rooms_count: `{count} {count, plural,
        one {room}
        other {rooms}
      }`,
      rooms_count_only_label: `{count, plural,
        one {room}
        other {rooms}
      }`,
      available_rooms: `There {count, plural,
        one {is}
        other {are}
        {count} available {count, plural,
        one {room}
        many {rooms}
        }`,
      general: 'General'
    },
    periods: {
      caption: 'Periods of pricing',
      self: 'Additional availability period',
      empty: 'If necessary, add one or more pricing periods',
      prices: 'Prices',
      add: 'Add period',
      delete: 'Delete periods',
      start_date: 'Available period',
      rates: 'Rates',
      date_not_defined: 'Not defined'
    },
    services: {
      general: 'General',
      foodservice: 'Food service',
      medical_and_rehabilitation: 'Medical and rehabilitation',
      service: 'Service',
      price: 'Price',
      description: 'Description',
      type: 'Type',
      add: 'Add service',
      new: 'New service'
    },
    dashboard: {
      new_booking: 'New booking',
      room_description: 'Room description',
      booking_details: 'Booking details',
      dates: 'Dates',
      room_type: 'Room type',
      rate: 'Rate',
      tariff: 'Tariff',
      guest_details: 'Guest details',
      check_in_date: 'Check-in date',
      check_out_date: 'Check-out date',
      cancel_booking: 'Cancel booking',
      confirm_booking: 'Confirm booking',
      booking_dates: 'Dates',
      booking_room_type: 'Room type',
      booking_passport: 'Passport',
      booking_tariff: 'Rate',
      booking_amount: 'Amount',
      booking_source: 'Source',
      not_found: 'There are not seats on this period',
      available_seats: 'Seats: { main } main, { extra } extra',
      beds: 'Main seats',
      extra_beds: 'Extra beds',
      booking_code: 'Booking',
      booking_expires_at: 'Expires at',
      total: 'Total',
      all_orders: 'To all orders',
      quota: {
        self: 'Allotment',
        available: 'Available',
        booked: 'Booked',
        allotment: 'Allotment',
        edit: {
          self: 'Edit quota',
          range_error: 'Range not selected',
          end_range_error: 'End date not selected. If it\'s one day, click it again',
          quota: {
            self: 'Quota',
            disclaimer: 'When changing the quota, the available numbers recalculated automatically:',
            formula: 'Available = Quota - Busy',
            limit_error: 'Quota ({sourceName}) exceeds the limit ({limit})'
          },
          availability: {
            self: 'Availability',
            disclaimer: 'When the number of available rooms changes, the quota is recalculated automatically:',
            formula: 'Quota = Available + Busy'
          }
        }
      }
    },
    hotel: {
      object_type: 'Object type',
      property_description: 'Property description',
      address: 'Address',
      star: 'Number of stars',
      star_description: 'If the star rating does not apply to your object, leave the value 0',
      feed: 'Feed',
      feedType: 'Feed type',
      meal_options: 'Meal options',
      treatment_factors: 'Treatment factors',
      location_types: 'Location types',
      accommodation_features: 'Accommodation features',
      feedType_didnt_choosed: 'You have not selected any feed types',
      meal_options_didnt_choosed: 'You have not selected any meal options',
      location_types_didnt_choosed: 'You have not selected any location types',
      accommodation_features_didnt_choosed: 'You have not selected any accommodation features',
      treatment_factors_didnt_choosed: 'You have not selected any treatment factors',
      object_type_didnt_choosed: 'You have not selected any object type',
      name: 'Name',
      night: 'Night',
      before: 'Before',
      after: 'After',
      day: 'Day',
      email: 'E-mail',
      phone: 'Phone',
      description: 'Description',
      website: 'Website',
      coordinates: 'Coordinates',
      treatment_profiles: 'Profiles of treatment',
      add_specialization_hint: 'Choose one or more profiles of treatment',
      treatment_profiles_didnt_choosed: 'You have not selected any profiles of treatment',
      add_photo: 'Add photo',
      checkout_time_and_age_limits: 'Check-out time and age limits',
      calculate_period_in: 'Checkout time',
      accommodation: 'Accommodation',
      age_limit: 'Age limit',
      checkin_time_after: 'Check-in time after',
      checkout_time_before: 'Check-out time',
      children_age_any: 'All children are welcome',
      children_age_from: `Children from {years} {years, plural,
        one {year}
        other {years}
      } are welcome`,
      amenities_and_services: 'Amenities and services',
      add_amenities_hint: 'Add one or more services and/or amenities',
      amenities_didnt_choosed: 'You have not selected any services and/or amenities',
      contact_information: 'Contact information',
      add_email: 'Add E-mail',
      add_phone: 'Add phone',
      additional_communication_channels: 'Additional communication channels',
      general_information: 'General information',
      payment_details: 'Payment details',
      card_types_accepted: 'The following types of bank cards are accepted:',
      use_for_notification: 'Use to notify about new bookings',
      payment_by_cash: 'Payment at the front desk',
      payment_by_card: 'Cashless payment when booking',
      by_prepayment: 'Prepayment',
      payment_by_prepayment: 'Prepayment allowed',
      payment_by_card_not_accepted: 'Bank cards are not accepted for payment',
      payment_alowed: 'Payment is allowed',
      payment_not_alowed: 'Payment is not allowed',
      settings: {
        billing_hour: 'Calculation of the period of stay ',
        night: 'n nights',
        day: 'in days',
        billing_hour_hint: 'To change the type of calculation, please contact the Support Service',
        source_without_confirmation: 'Source is no need for confirmation',
        source_with_confirmation: 'Source is need for confirmation'
      },
      currency: 'Currency'
    },
    buildings: {
      self: 'Buildings and blocks',
      name: 'Name',
      floors: 'Floors',
      add: 'Add block',
      name_of_the_block: 'Name of the block'
    },
    room_types: {
      general: 'General',
      self: 'Room types',
      name: 'Name',
      beds: 'Seats',
      extra_beds: 'Extra seats',
      multiple_occupation_allowed: 'Multiple occupation allowed',
      add: 'Add room',
      sngl_std: 'Single, standard',
      room_description: 'Editing room',
      available_beds: 'Available beds',
      photos: 'Room Photos',
      delete_photos: 'Delete all',
      no_photo: 'No photo',
      modal_room_counts: 'Total rooms: ',
      modal_description: 'Indicate the total number of rooms of this type',
      area: 'Square meter',
      area_full: 'Square meter',
      area_placeholder: 'sq m.',
      rm_count: 'Rooms count',
      change_the_quantity: 'Сhange the quantity',
      description: 'Description',
      room_count: `{rooms} {rooms, plural,
        zero: {room}
        one {room}
        few {rooms}
        other {rooms}
      }`,
      capacity: `{capacity} {capacity, plural,
        one {room}
        few {rooms}
        other {rooms}
      }`,
      beds_count: `{beds} {beds, plural,
        zero: {seat}
        one {seat}
        few {seats}
        other {seats}
      }`,
      extra_beds_count: `{beds} {beds, plural,
        zero: {extra seat}
        one {extra seat}
        few {extra seats}
        other {extra seats}
      }`
    },
    rooms: {
      room_number: 'Room number',
      floor: 'Floor',
      block: 'Block',
      room_type: 'Room type',
      add: 'Add room',
      not_specified: 'Not specified'
    },
    orders: {
      add: 'New order',
      list: 'Order list',
      more_about_order: 'More about ordering',
      comments: 'Comments',
      room_type_and_tariff: 'Roon type and tariff',
      guests: 'Guests',
      price: 'Price',
      order_number: 'Order №{code}',
      expired_at: 'Expired at',
      statuses: {
        booked: 'Booked',
        confirmed: 'Confirmed',
        confirmed_description: '(Transcription required)',
        cancelled: 'Cancelled',
        unknown: 'Unknown'
      },
      types: {
        insurance: 'Insurance',
        hotel: 'Sanatory'
      },
      payment_method: {
        bank_transfer: 'cashless',
        cash: 'cash',
        total: 'total'
      },
      status: 'Status',
      filter: 'Filter',
      type: 'Source',
      in_out: 'Check-in/Check-out',
      check_in: 'Check-in',
      check_in_data: 'Check-in after',
      check_out: 'Check-out',
      check_out_data: 'Check-out before',
      hotel: 'Sanatorium',
      manager: 'Manager',
      managers_placeholder: 'Start typing last name or first name',
      hotels_placeholder: 'Start typing the name of the sanatorium',
      guestName: 'Guest name',
      source: 'Source',
      last_changed: 'Modified',
      travellers_count: `{count} {count, plural,
        one {traveller}
        other {travellers}
      }`,
      email: 'E-mail',
      phone: 'Phone',
      code: '№',
      clear: 'Clear'
    }
  }
};
